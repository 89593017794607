import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import MonitorsStore from '../../mobx/monitors';
import Field from '../Form/Field';
import Select from '../Form/Select';
import ProjectsStore from '../../mobx/projects';
import Input from '../Form/Input';
import Icon from '../Icon';
import Alert from '../Alert';
import ApiClient from '../../core/apiClient';

const HealthCheckCreate = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectProject, setSelectProject] = useState(true);
  const [newProjectName, setNewProjectName] = useState('');
  const [host, setHost] = useState('http://');
  const [form, setForm] = useState({
    url: '', name: '', project_id: -1, type: 'health',
  });
  const [testRequestAlert, setTestRequestAlert] = useState('');
  const [testRequestLoading, setTestRequestLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'url') setTestRequestAlert('');
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  const handleClick = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const testRequest = () => {
    setTestRequestLoading(true);
    ApiClient.testConnection('health', host + form.url).then((res) => {
      if (res.data?.result === true) {
        setTestRequestAlert(t('Endpoint is responding!'));
      } else {
        setTestRequestAlert(res.data?.state?.error);
      }
      setTestRequestLoading(false);
    }).catch(() => {
      setTestRequestAlert(t('The selected type is invalid'));
      setTestRequestLoading(false);
    });
  };

  const createMonitor = (newForm?: { url: string, name: string, project_id: number, type: string }) => {
    ApiClient.createMonitor(newForm ? newForm : { ...form, url: host + form.url }).then((res) => {
      MonitorsStore.monitors.push(res.data.monitor);
      history.push(`/project/${newForm ? newForm.project_id : form.project_id}`);
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const createProject = () => {
    ApiClient.createProject(newProjectName).then((res) => {
      ProjectsStore.projects.push(res.data.project);
      setForm({ ...form, project_id: res?.data?.project?.id });

      const newForm = { ...form, url: host + form.url };
      newForm.project_id = res?.data?.project?.id;

      createMonitor(newForm);
    });
  };

  const onCreate = () => {
    setIsLoading(true);
    if (newProjectName.length) {
      createProject();
      return;
    }

    createMonitor();
  };

  return (
    <>
      <div className="create-template">
        <div className="title">{t('Your Custom Health-check')}</div>
        <div className="t-input clue">{t('Follow instructions to setup monitoring')}</div>
        <div className="t-headline-4 pre-input">1. {t('Select project and enter title')}</div>
        <div className="select-project">
          <Field label={t('Project')}>
            {selectProject ? (
              <Select
                options={ProjectsStore.projects.map((p) => p.name)}
                defaultValue={ProjectsStore.projectTabName}
                onChange={selectChangeHandler}
              />
            )
              : (
                <Input
                  value={newProjectName}
                  onChange={(e: any) => setNewProjectName(e.target.value)}
                />
              )}
          </Field>
          <div className="button-add-project" onClick={() => setSelectProject(!selectProject)}>
            {selectProject ? <Icon name="folder" /> : <Icon name="close" /> }
          </div>
        </div>
        <div className="f-title">
          <Field label={t('Title')}>
            <Input
              placeholder={t('Enter title')}
              value={form.name}
              onChange={changeHandler}
              name="name"
            />
          </Field>
        </div>
        <div className="t-headline-4 pre-input">2. {t('Enter path to your health-check')}</div>
        <div className="t-input clue">
          {t('HEALTH_CHECK_DESCRIPTION_SUCCESS')}
        </div>
        <div className="block-copy">
          <div className="block-copy__item__json" onClick={() => handleClick('{state: \'ok\',error:\'\'}')}>
            <div className="block-copy__item-text">{'{'}
              state: &apos;ok&apos;,
              error: &apos;&apos;
              {'}'}
            </div>
            <div className="block-copy__copy">
              <Icon name="copy" />
            </div>
          </div>
        </div>
        <div className="t-input clue">
          {t('HEALTH_CHECK_DESCRIPTION_ERROR')}
        </div>
        <div className="block-copy">
          <div className="block-copy__item__json" onClick={() => handleClick('{state: \'error\',error:\'Any error message\'}')}>
            <div className="block-copy__item-text">{'{'}
              state: &apos;error&apos;,
              error: &apos;Any error message&apos;
              {'}'}
            </div>
            <div className="block-copy__copy">
              <Icon name="copy" />
            </div>
          </div>
        </div>
        <div className="f-title f-server-address">
          <Field label={t('Website address')}>
            <Input
              placeholder={t('Enter website address')}
              value={form.url}
              onEnter={testRequest}
              onChange={changeHandler}
              select={['http://', 'https://']}
              onChangeSelect={setHost}
              name="url"
            />
          </Field>
        </div>
        <div className="server-address-test">
          <Button text={t('Test connection')} bc="main2" size="big" onClick={testRequest} loading={testRequestLoading} disabled={form.url.length < 1} />
          {testRequestAlert.length && testRequestAlert === t('Endpoint is responding!')
            ? <Alert type="success" title={testRequestAlert} size="small" />
            : testRequestAlert.length ? <Alert type="warning" title={testRequestAlert} size="small" /> : ''}
        </div>
      </div>
      <div className="add-monitoring">
        <Button
          text={t('Add monitoring')}
          bc="main18"
          size="big"
          onClick={onCreate}
          loading={isLoading}
          disabled={
          form.url.length < 1 || form.name.length < 1 || !form.project_id || testRequestAlert !== t('Endpoint is responding!')
        }
        />
      </div>
    </>
  );
});

export default HealthCheckCreate;
